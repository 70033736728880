<template>
  <div class="page">
    <div class="page-main">
      <el-form class="form-max-900" ref="form" :model="form" :rules="rules" label-width="auto">
        <el-form-item :label="$t('task.taskName')" prop="name">
          <el-input
            v-model="form.name"
            :placeholder="$t('task.taskNamePla')"
            :maxlength="128"
          ></el-input>
        </el-form-item>
        <!-- 任务时间 -->
        <el-form-item :label="$t('task.taskTime')" prop="type">
          <el-radio-group v-model="form.type">
            <el-radio v-for="(val, key) in taskTimeType" :key="key" :label="+key">
              {{ val }}
            </el-radio>
          </el-radio-group>
          <!-- 定时 -->
          <el-form-item prop="beginTime" v-if="form.type == 2">
            <el-date-picker
              ref="beginTimeRef"
              v-model="form.beginTime"
              type="datetime"
              style="width: 100%;"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item>
          <select-repeating-time
            just-display-time="true"
            v-if="form.type == 3"
            :beginTime.sync="form.beginTime"
            :repeateType.sync="form.repeateType"
            :repeateTime.sync="form.repeateTime"
          ></select-repeating-time>
        </el-form-item>
        <!-- 任务类型 -->
        <el-form-item :label="$t('task.taskType')" prop="title">
          <div class="task-type">
            <dist-select
              v-model="form.title"
              :optionData="taskType"
              @change="taskTypeChange"
              :style="{ width: isTaskParam ? 'calc(50% - 5px)' : '100%' }"
            ></dist-select>
            <el-select
              v-if="isTaskParam"
              v-model="form.taskParam"
              style="width: calc(50% - 5px);margin-left: 10px;"
              :placeholder="placeholderList[form.title]"
              @change="templateIdChange"
            >
              <el-option
                v-for="item in taskParamOption"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <!-- 附件选择 -->
            <template v-if="form.title == 8 || form.title == 9 || form.title == 10">
              <el-form-item  style="width: calc(50% - 5px);margin-left: 10px;" prop="taskParam">
                <el-select
                  class="file-select"
                  v-model="form.taskParam"
                  :placeholder="$t('task.filePla')"
                  @change="templateIdChange"
                >
                  <el-option
                    v-for="fileOption in fileOptions"
                    :key="fileOption.id"
                    :label="fileOption.resourceName + ' - ' +fileOption.fileName"
                    :value="fileOption.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </template>
            <!-- 升级固件 -->
            <template v-if="form.title == 3">
              <!-- 模块 -->
              <el-select
                v-model="form.taskModel"
                style="width: calc(50% - 5px);margin-left: 10px;"
                :placeholder="$t('task.modelPla')"
                @change="changeTaskModel"
              >
                <el-option
                  v-for="modelOption in modelOptions"
                  :key="modelOption.model"
                  :label="modelOption.model"
                  :value="modelOption.model"
                ></el-option>
              </el-select>
              <!-- 固件 -->
              <SelectTree
                v-model="form.taskParam"
                :data="firmwareOptions"
                :props="{
                  value: 'id',
                  children: 'child',
                  label: 'firmwareVersion',
                  subLabel: 'firmwareName'
                }"
                default-expand-all
                highlight-current
                :placeholder="$t('task.versionPla')"
                @change="templateIdChange"
                style="width: calc(50% - 5px);margin-left: 10px;"
              />
            </template>
          </div>
        </el-form-item>
        <!-- 选择设备 -->
        <el-form-item :label="$t('task.runDevice')" prop="deviceType">
          <el-radio-group v-model="form.deviceType">
            <el-radio :label="3" v-if="isTaskParam">
              <template v-if="form.title == 4">{{ $t('allModelDevice') }}</template>
              <template v-if="form.title == 5">{{ $t('allGroupDevice') }}</template>
              <template v-if="form.title == 6">{{ $t('allSiteDevice') }}</template>
            </el-radio>
            <el-radio :label="1">{{ $t('task.selectDevice') }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-form :model="form" :rules="rules">
        <el-form-item prop="macs">
          <select-device
            ref="selectDevice"
            :macs.sync="form.macs"
            :devices.sync="form.devices"
            :deviceType.sync="form.deviceType"
            :toolsDisplay="toolsDisplay"
          ></select-device>
        </el-form-item>
      </el-form>
      <div class="footer-btns" style="text-align: center;">
        <el-button class="footer-btn" @click="$router.go(-1)">{{ $t('cancel') }}</el-button>
        <el-button class="footer-btn" type="primary" @click="submit">{{ $t('save') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import { taskQuery, taskAdd, taskUpdate, taskFileQuery } from '@/api/task'
import { modelTempSelect, modelTempSite, supportModelSelect } from '@/api/model'
import { selectUpgradeVersionList } from '@/api/firmware'
import { groupTempSelect } from '@/api/group'
import { siteTempSelect } from '@/api/site'
import { DistSelect, SelectDevice, SelectTree, SelectRepeatingTime } from '@/components'

export default {
  name: 'TaskDetails',
  components: {
    SelectRepeatingTime,
    DistSelect,
    SelectDevice,
    SelectTree
  },
  data() {
    const nowTime = new Date().getTime()
    return {
      nowTime,
      pickerOptions: {
        disabledDate(time) {
          const todayTimestamp = dayjs().startOf('date').unix(),
            timestamp = dayjs(time).unix()
          return timestamp < todayTimestamp
        }
      },
      fileOptions: [],
      form: {
        name: '',
        // 任务时间
        type: 1,
        beginTime: nowTime + 600000,
        repeateType: 1,
        repeateTime: null,
        // 任务类型
        title: "1",
        taskParam: '',
        // 选择设备
        deviceType: 1,
        taskModel: null,  // 模块
        // taskFirmware: null,  // 固件
        macs: [],
        deviceIds: [],
        devices: [],
      },
      rules: {
        name: {
          required: true,
          message: this.$t('task.taskNameRule'),
          trigger: 'blur',
        },
        type: { required: true },
        title: { required: true },
        deviceType: { required: true },
        beginTime: {
          validator: (rule, value, callback) => {
            const nowTime = parseInt(new Date().getTime()/1000)*1000
            let legalTime = new Date().getTime() + (5 * 60 * 1000)
            if(nowTime == value) {
              this.form.beginTime = value + (10 * 60 * 1000)
            } else if(value - legalTime < 0) {
              callback(new Error(this.$t('task.taskBeginTimeRule')))
            } else {
              callback()
            }
          },
          trigger: 'change',
        },
        taskParam: {
          required: true,
          message: this.$t('task.taskParamRule'),
          trigger: 'change',
        },
      },
      taskParamOption: [],  // 任务类型
      modelOptions: [],  // 模块选项
      firmwareOptions: [],  // 固件选项
      placeholderList: {
        4: this.$t('task.modelPla1'),
        5: this.$t('task.groupPla'),
        6: this.$t('task.sitePla')
      }
    }
  },
  computed: {
    ...mapGetters('dist', ['taskTimeType', 'taskType']),
    isTaskParam() {
      return ['4', '5', '6'].includes(this.form.title)
    },
    toolsDisplay() {
      let obj = undefined
      switch (this.form.title) {
        case '1': {
          obj = {
            siteShow: true,
            modelShow: true,
            keywordsShow: true,
          }
          break
        }
        case '2': {
          obj = {
            siteShow: true,
            modelShow: true,
            keywordsShow: true,
          }
          break
        }
        case '3':
          obj = {
            siteShow: true,
            keywordsShow: true,
          }
          break
        case '4':
          obj = {
            keywordsShow: true,
          }
          break
        case '5':
          obj = {
            siteShow: true,
            modelShow: true,
            keywordsShow: true,
          }
          break
        case '6':
          obj = {
            modelShow: true,
            keywordsShow: true,
          }
          break
        case '7':
          obj = {
            siteShow: true,
            modelShow: true,
            keywordsShow: true,
          }
          break
      }
      return obj
    },
  },
  watch: {
    'form.devices'(val) {
      this.form.deviceIds = val.map((e) => e.id)
      this.form.macs = val.map((e) => e.macAddress)
    },
  },
  created() {
    this.$route.query.id && this.getData(this.$route.query.id)
  },
  methods: {
    getResourcesTypeByTaskType(type){
      let dict = {
        8: 1,
        9: 2,
        10: 3,
      }
      return dict[type]
    },
    // 获取数据
    getData(id) {
      taskQuery({ id })
        .then(({ data }) => {
          this.form = data.result.rows[0]
          this.form.title = +this.form.title+''
          this.taskTypeChange(this.form.title, false)
          this.form.beginTime == null && (this.form.beginTime = this.nowTime + 600000)
          this.form.macs = this.form.devices.map((e) => e.macAddress)
        })
        .catch(() => {})
        this.taskFile(this.form.title)
    },
    // 切换任务类型
    taskTypeChange(val, reset = true) {
      if (reset) {
        this.form.taskParam = ''
        this.templateIdChange()
        if (this.isTaskParam) {
          this.form.deviceType != 3 && (this.form.deviceType = 3)
        } else {
          this.form.deviceType != 1 && (this.form.deviceType = 1)
        }
      }
      const tempApi = {
        4: modelTempSelect,
        5: groupTempSelect,
        6: siteTempSelect,
      }
      if (tempApi[val]) {
        if(val == 4) this.selectDeviceTabelChange({model: [], groupId: null, site: null})
        if(val == 5) this.selectDeviceTabelChange({model: null, groupId: null, site: null})
        if(val == 6) this.selectDeviceTabelChange({model: null, groupId: null, site: []})
        tempApi[val]()
          .then(({ data }) => {
            const rows = data.result.rows
            this.taskParamOption = val == 4 ? rows.map((e)=> {
              e.name = `${e.name}(${e.model})`
              return e
            }) : rows
          })
      } else if (val == 8 || val == 9 || val == 10) {
        this.taskFile(val)
      } else if (val == 3) {
        this.selectDeviceTabelChange({model: [], groupId: null, site: null})
        supportModelSelect().then(({ data }) => {
          this.modelOptions = data.result.rows
        })
      }
    },
    // 获取任务文件参数
    taskFile(val){
       let data = {
          fileType: this.getResourcesTypeByTaskType(val),
          keywords: "",
          site: [],
          sortField: "updateTime",
          sortOrder: "desc"
        }
        taskFileQuery(data)
          .then(({ data }) => {
            console.log('file', data.result.rows)
            this.fileOptions = data.result.rows
          })
    },
    // 模板列表切换
    templateIdChange(id) {
      switch (this.form.title) {
        case '3':
          if (id) this.selectDeviceTabelChange({ model: [this.form.taskModel], groupId: null, site: null })
          break
        case '4':
          if (id) {
            modelTempSite({ id })
              .then(({ data }) => {
                this.selectDeviceTabelChange({
                  model: [this.taskParamOption.find((e) => e.id == id).model],
                  groupId: null,
                  site: data.result.rows,
                })
              })
              .catch(() => {})
          } else {
            this.selectDeviceTabelChange({ model: null, groupId: null, site: null })
          }
          break
        case '5':
          this.selectDeviceTabelChange({ model: null, groupId: id ? id : null, site: null })
          break
        case '6':
          this.selectDeviceTabelChange({
            model: null,
            groupId: '',
            site: id ? [this.taskParamOption.find((e) => e.id == id).model] : null,
          })
          break
        default:
          this.selectDeviceTabelChange({ model: null, groupId: null, site: null })
          break
      }
    },
    // 修改模块
    changeTaskModel() {
      const prarms = {
        site: [],
        supportModel: [this.form.taskModel]
      }
      selectUpgradeVersionList(prarms).then(({ data }) => {
        this.firmwareOptions = data.result.rows
      })
    },
    // 触发表格过滤
    selectDeviceTabelChange({ model, groupId, site }) {
      this.$refs.selectDevice.tabelSearchChange({ model, groupId, site })
    },
    // 保存
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let params = {
            name: this.form.name,
            type: this.form.type,
            repeateType: this.form.repeateType,
            repeateTime: this.form.repeateTime,
            title: this.form.title,
            deviceType: this.form.deviceType,
          }
          // 任务时间
          if (this.form.type == 1) {
            params.beginTime = null
          }
          if (this.form.type == 2) {
            params.beginTime = this.form.beginTime.valueOf()
          }
          if (this.form.type == 3) {
            params.beginTime = this.form.beginTime
            params.repeateType = this.form.repeateType
            params.repeateTime = this.form.repeateTime
          }
          // 任务类型
          console.log('type = ', this.form.type)
          if (this.isTaskParam || this.form.title == 3 || this.form.title == 8 || this.form.title == 9 || this.form.title == 10) {
            params.taskParam = this.form.taskParam
            params.template = this.form.taskParam
          }
          // 选择设备
          if (this.form.deviceType == 1) params.deviceIds = this.form.deviceIds
          if (this.form.deviceType == 2) params.macs = this.form.macs
          // 接口
          const submitApi = ['addTask', 'runAgainTask'].includes(this.$route.name)
            ? taskAdd
            : taskUpdate
          if (this.form.id) params.id = this.form.id
          submitApi(params)
            .then(({ data }) => {
              this.$message.success(data.message)
              this.$router.go(-1)
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@include form-page;
.page-main {
  padding: 64px 0;
  .footer-btns {
    justify-content: center;
  }
  .task-type {
    display: flex;
    justify-content: space-between;
  }
}
</style>