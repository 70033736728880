import axios from '@/plugins/axios.js'

// 查询（分页）
export const taskSearch = (params) => {
  let newParams = { ...params }
  newParams.site = params.site == '' ? null : params.site
  return axios.post('/task/search', newParams)
}

// // 查询（list）
// export const serverList = (params) => axios.get('/server/list', { params })

// 查询（id）
export const taskQuery = (params) => axios.get('/task/query', { params })

// 批量删除
export const removeTaskBatch = (data) => axios.delete('/task/removeBatch', { data })

// 新增
export const taskAdd = (params) => axios.post('/task/create', params)

// 更新
export const taskUpdate = (params) => axios.post('/task/update', params)

// 执行任务
export const taskExcute = (params) => axios.post('/task/excute', params)

// 暂停任务
export const taskPause = (params) => axios.post('/task/pause', params)

// 任务详情列表
export const taskDetailList = (params) => axios.post('/task/detailList', params)

// 统计
export const taskCount = (params) => axios.post('/task/detailCount', params)

// 更新
export const taskFileQuery = (params) => axios.post('/tb-resources/selectResourcesList', params)